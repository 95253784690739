import './CaseCoconessa.scss'

function CaseCoconessa() {
   return (
      <section className="coconessa case_project">
         <div className="container">
            <div className="coconessa_wrap">
               <div className="case_project_top row">
                  <div className="col-xl-3">
                     <div className="case_project_top_info">
                        <div className="case_project_top_info_head">
                           <h1 className="case_project_top_info_head-title">
                              Сoconessa
                           </h1>
                           <div className="case_project_top_info_head-text">
                              <p>
                                 Редизайн бренда веганских конфет&nbsp;&mdash; Coconessa. Coconessa&nbsp;&mdash; это бренд натуральных конфет из&nbsp;кокоса. Удовольствие без чувства вины
                                 и&nbsp;искусственных добавок.
                              </p>
                           </div>
                        </div>
                        <div className="case_project_top_info_team">
                           <div className="case_project_top_info_team-title">Работали над проектом:</div>
                           <div className="case_project_top_info_team_list">
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/people.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/mikhail.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/dmitry-sukhov.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/kristina.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/people2.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/ben.jpg" alt="" />
                              </div>
                              <div className="case_project_top_info_team_list-it">
                                 <img src="./img/team/alexander-rodionov.jpg" alt="" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5">
                     <div className="case_project_top_info-image">
                        <img src="./img/coconessa/head.jpg" alt="Coconessa" />
                        <a href="https://coconessa.ru/" className='case_project_top_info-image-link' target='_blank' rel='nofollow noreffer' aria-label='Перейти на сайт' title='Перейти на сайт'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                              <g filter="url(#filter0_b_1382_3221)">
                                 <rect width="32" height="32" rx="16" fill="white" fillOpacity="0.2" />
                                 <path d="M21.248 18.25C20.8339 18.25 20.4981 18.5858 20.4981 19V19.75C20.4981 20.1642 20.1624 20.5 19.7483 20.5H12.2496C11.8355 20.5 11.4997 20.1642 11.4997 19.75V12.25C11.4997 11.8358 11.8355 11.5 12.2496 11.5H12.9995C13.4136 11.5 13.7493 11.1642 13.7493 10.75C13.7493 10.3358 13.4136 10 12.9995 10H12.2496C11.0072 10 10 11.0074 10 12.25V19.75C10 20.9926 11.0072 22 12.2496 22H19.7483C20.9907 22 21.9979 20.9926 21.9979 19.75V19C21.9979 18.5858 21.6622 18.25 21.248 18.25Z" fill="white" />
                                 <path d="M21.9973 10.6037C21.9973 10.5813 21.9973 10.5588 21.9785 10.54C21.9724 10.5146 21.9648 10.4896 21.956 10.465C21.945 10.4393 21.9324 10.4143 21.9185 10.39C21.9185 10.39 21.9185 10.3525 21.8886 10.3338C21.834 10.2522 21.7639 10.1821 21.6823 10.1275L21.6223 10.0938L21.5511 10.0563L21.4724 10.0338H21.4086C21.3563 10.0166 21.3022 10.0053 21.2474 10H16.7482C16.3341 10 15.9983 10.3358 15.9983 10.75C15.9983 11.1642 16.3341 11.5 16.7482 11.5H19.4365L15.0947 15.8463C14.8018 16.1393 14.8018 16.6144 15.0947 16.9075C15.3877 17.2006 15.8628 17.2006 16.1558 16.9075L20.4975 12.5612V15.25C20.4975 15.6642 20.8333 16 21.2474 16C21.6616 16 21.9973 15.6642 21.9973 15.25V10.75C22.0021 10.7014 22.0021 10.6524 21.9973 10.6037Z" fill="white" />
                              </g>
                              <defs>
                                 <filter id="filter0_b_1382_3221" x="-100" y="-100" width="232" height="232" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="50" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1382_3221" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1382_3221" result="shape" />
                                 </filter>
                              </defs>
                           </svg>
                        </a>
                     </div>
                  </div>
               </div>
               <div className="case_project_body">
                  <div className="case_project_body_section">
                     <div className="case_project_body_section_text row">
                        <div className="col-xl-2">
                           <div className="case_project_body-small_text">
                              <p>
                                 Линейка продукции состоит из базовой серии «сливочная» Milky Vegan
                                 и полностью растительного сгущенного молока.
                              </p>
                           </div>
                        </div>
                        <div className="offset-xl-1 col-xl-4">
                           <div className="case_project_body-middle_text">
                              <p>
                                 Новый дизайн включает в себя дизайн-блоки с описанием продукта, яркие узоры и прозрачное окошко в форме конфеты, позволяющее покупателю рассмотреть продукт.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="case_project_body_section_images row">
                        <div className="col-xl-2">
                           <div className="case_project_body_image square">
                              <img src="./img/coconessa/square.jpg" alt="" />
                           </div>
                        </div>
                        <div className="offset-xl-1 col-xl-5">
                           <div className="case_project_body_image hor">
                              <img src="./img/coconessa/hor.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <div className="case_project_body_section">
                     <div className="case_project_body_section_images row">
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical1.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical2.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                  </div> */}
                  <div className="case_project_body_section">
                     <div className="case_project_body_section_text row">
                        <div className="col-xl-2">
                           <div className="case_project_body-small_text">
                              <p>
                                 Milky Vegan
                              </p>
                           </div>
                        </div>
                        <div className="offset-xl-1 col-xl-4">
                           <div className="case_project_body-middle_text">
                              <p>
                                 Дизайн серии Milky Vegan создан в цветах и ​​узорах основных вкусов: матча, смородина и малина. Блок дизайна завершается названием продукта, причем не ровным краем, а капающим контуром, словно стекающим с упаковки. Этот быстро узнаваемый штамп обещает своей аудитории нежный кремовый опыт.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="case_project_body_section_images row">
                        <div className="col-xl-2">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical3.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-2">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical4.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-2">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical5.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-2">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical6.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="case_project_body_section">
                     <div className="case_project_body_section_text row">
                        <div className="col-xl-2">
                           <div className="case_project_body-small_text">
                              <p>
                                 Сгущенное молоко
                              </p>
                           </div>
                        </div>
                        <div className="offset-xl-1 col-xl-4">
                           <div className="case_project_body-middle_text">
                              <p>
                                 Упаковка кокосового сгущенного молока выполнена в том же красочном стиле.  Вся информация размещена на небольшом фирменном блоке дизайна: название, состав и информация
                                 о продукте.
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="case_project_body_section_images row">
                        <div className="col-xl-8">
                           <div className="case_project_body_image hor big">
                              <img src="./img/coconessa/hor2.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical7.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical8.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="case_project_body_section">
                     <div className="blockquote">
                        <div className="blockquote-text case_project_body-middle_text">
                           <p>
                              “Редизайн был сосредоточен на том, чтобы создать визуальный образ, который отражает натуральность и экологичность веганских конфет,  делая их привлекательными для осознанных потребителей, ценящих чистоту и простоту.”
                           </p>
                        </div>
                        <div className="blockquote_author">
                           <div className="blockquote_author-image">
                              <img src="./img/team/ben.jpg" alt="" />
                           </div>
                           <div className="blockquote_author_meta">
                              <div className="blockquote_author_meta-name">Бен Папян</div>
                              <div className="blockquote_author_meta-job">Арт-директор</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="case_project_body_section">
                     <div className="case_project_body_section_images row">
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical9.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">

                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical10.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">

                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical11.jpg" alt="" />
                           </div>
                        </div>
                        <div className="col-xl-4">
                           <div className="case_project_body_image vertical">
                              <img src="./img/coconessa/vertical12.jpg" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default CaseCoconessa;