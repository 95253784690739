import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FontFaceObserver from 'fontfaceobserver';
import { useMediaQuery } from 'react-responsive'

function CaseBenefit({ benefit }) {

   const isShowSlider = useMediaQuery({
      query: '(max-width: 900px)'
   })
   const swiperRef = useRef(null);

   useEffect(() => {
      const font = new FontFaceObserver('Pitagon Sans Mono');

      font.load().then(() => {
         if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
         }
      });
   }, []);
   return (

      benefit.length ? (
         <div className="case_benefit">
            {!isShowSlider ? (
               benefit.map((item, index) => (
                  <div className="case_benefit-it" key={index}>{item}</div>
               ))
            ) : (
               <Swiper
                  ref={swiperRef}
                  className='case_benefit_sw'
                  slidesPerView='auto'
                  spaceBetween={20}
               >
                  {benefit.map((item, index) => (
                     <SwiperSlide className="case_benefit-it" key={index}>{item}</SwiperSlide>
                  ))}
               </Swiper>
            )}
         </div>
      ) : (
         ''
      )
   );
}

export default CaseBenefit;