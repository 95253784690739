import CaseList from "./components/Cases/CaseList";
import FrontPageCases from "./components/FrontPage/FrontPageCases/FrontPageCases";
import FrontPageFirst from "./components/FrontPage/FrontPageFirst/FrontPageFirst";

function FrontPage() {

   return (
      <main>

         <FrontPageFirst />
         <FrontPageCases>
            <CaseList />
         </FrontPageCases>
      </main>
   );
}

export default FrontPage;
