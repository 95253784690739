import React, { useState, useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import './AnimatedRoutes.scss'
import Preloader from '../components/Preloader';

const AnimatedRoutes = ({ children }) => {
   const location = useLocation();
   const [displayLocation, setDisplayLocation] = useState(location);
   const [transitionStage, setTransistionStage] = useState("fadeIn");

   useEffect(() => {
      if (location !== displayLocation) {
         setTransistionStage("fadeOut")
      };

   }, [location, displayLocation]);

   useEffect(() => {
      // Прокрутка страницы наверх при изменении displayLocation
      window.scroll(0, 0);
   }, [displayLocation]);

   return (
      <>
         <div className={`animate_page ${transitionStage}`}
            onAnimationEnd={() => {
               if (transitionStage === "fadeOut") {
                  setTransistionStage("fadeIn");
                  setDisplayLocation(location);
               }
               document.querySelector('html').style.scrollBehavior = 'auto';
            }}>
            <svg width="123" height="40" viewBox="0 0 123 40" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path className='path1' d="M34.2536 12.1946L33.9355 12.4711L32.7925 13.4645L29.2569 16.5375L23.4038 21.6248C22.8631 22.0948 22.3841 22.6945 22.1457 23.413C21.9028 24.1449 21.9221 24.9654 22.3261 25.8299C22.627 26.4739 23.3076 27.9424 23.9953 29.426C24.6011 30.733 25.2123 32.0518 25.5744 32.8295C26.2914 34.3691 26.0434 35.5999 25.2526 36.2305C24.4236 36.8934 22.8947 37.0518 21.4558 35.822C20.1429 34.6972 18.1673 33.0308 17.0483 32.087C16.802 31.8792 16.5972 31.7064 16.4501 31.5822C15.915 31.1302 15.1844 30.9774 14.5352 31.1223C13.8703 31.2707 13.2648 31.7403 13.0521 32.537C12.7339 33.7287 12.0527 36.3569 11.6113 38.0597C11.4285 38.7649 10.9628 39.2445 10.4262 39.4168C9.90368 39.5846 9.2307 39.4869 8.56433 38.8661C8.09589 38.4297 7.37474 37.7577 6.56739 37.0053C4.68531 35.2515 2.33478 33.0612 1.62528 32.4012C0.500936 31.3532 0.328182 30.1367 0.633849 28.9716C0.978709 27.6571 5.95142 8.67332 6.71083 5.77032C7.10958 4.24843 8.18076 3.45813 9.36869 3.22299C10.5848 2.98229 11.8925 3.3314 12.6925 4.07697C13.2825 4.62574 13.6907 5.00574 14.0014 5.29486C14.5775 5.83104 14.8178 6.05468 15.2583 6.46264C16.1296 7.26944 17.3191 7.19688 18.0557 6.55633C18.6059 6.07782 22.4265 2.76192 23.9235 1.46559C24.722 0.772348 25.5473 0.493643 26.2985 0.509951C27.0516 0.526298 27.7719 0.839775 28.3598 1.40552L28.3608 1.40654L34.3599 7.25668L34.364 7.26061C35.2547 8.10964 35.5464 9.05396 35.4834 9.90382C35.4193 10.7685 34.8701 11.6671 34.2536 12.1946ZM34.2536 12.1946L34.3654 12.0974L34.364 12.0986L34.3586 12.1033" stroke="black" strokeWidth="1" />
               <path className='path2' d="M48.4753 2.07944L48.5587 1.94374C49.1913 0.985325 49.9723 0.566908 50.7265 0.507548C51.5292 0.444366 52.3883 0.781255 53.1095 1.48087L60.0667 8.2254L60.0704 8.2289C60.8798 8.99781 61.3824 10.0501 61.4806 11.1521L63.5375 33.9314L63.5379 33.9351C63.7061 35.6556 62.5288 36.7703 61.1313 36.7291C60.5234 36.7107 59.8062 36.6127 58.9742 35.8103C58.3721 35.229 57.3626 34.2903 56.5068 33.4998C56.0781 33.1039 55.6869 32.7441 55.4028 32.4833L55.0657 32.1741L54.9737 32.0899L54.9498 32.0679L54.9447 32.0632C54.2736 31.4292 53.3802 31.0759 52.4504 31.0759H49.6826C48.4253 31.0759 47.2555 31.7232 46.6007 32.7892L43.4005 38.0021C42.3526 39.7088 40.1211 39.9942 38.6647 38.5959L38.6585 38.5902L32.7056 33.0648L32.704 33.0634C31.9924 32.3912 31.6455 31.6527 31.5705 30.9226C31.495 30.1874 31.6914 29.4194 32.1383 28.6912L48.4753 2.07944Z" stroke="black" strokeWidth="1" />
               <path className='path3' d="M107.087 2.07944L107.17 1.94375C107.803 0.985325 108.584 0.566908 109.338 0.507548C110.141 0.444366 111 0.781255 111.721 1.48087L118.678 8.2254L118.682 8.2289C119.491 8.99781 119.994 10.0501 120.092 11.1521L122.149 33.9314V33.9351C122.317 35.6556 121.14 36.7703 119.743 36.7291C119.135 36.7107 118.418 36.6127 117.585 35.8103C116.983 35.229 115.974 34.2903 115.118 33.4998C114.689 33.1039 114.298 32.7441 114.014 32.4833L113.677 32.1741L113.585 32.0899L113.561 32.0679L113.556 32.0632C112.885 31.4292 111.992 31.0759 111.062 31.0759H108.294C107.037 31.0759 105.867 31.7232 105.212 32.7892L102.012 38.0021C100.964 39.7088 98.7323 39.9942 97.276 38.5959L97.2698 38.5902L91.3169 33.0648L91.3154 33.0633C90.6037 32.3912 90.2568 31.6527 90.1818 30.9226C90.1063 30.1874 90.3027 29.4194 90.7496 28.6912L107.087 2.07944Z" stroke="black" strokeWidth="1" />
               <path className='path4' d="M74.2176 12.345L74.2415 12.2538C74.2732 12.1427 74.3018 12.0335 74.3274 11.926L74.3818 11.7183H74.3735C74.5561 10.832 74.5269 10.0725 74.3375 9.41065C74.104 8.59505 73.6407 7.97551 73.1263 7.47622L73.1235 7.47356L70.9487 5.39497C69.0948 3.6226 70.3523 0.505219 72.9777 0.505219H86.3928C88.0364 0.505219 89.6113 1.14224 90.7787 2.27337L94.6505 6.0262C96.2991 7.62481 95.1665 10.4098 92.8115 10.4098H91.2283C90.1731 10.3899 89.3008 10.6008 88.6116 11.1346C87.9257 11.6658 87.4904 12.4636 87.1979 13.4739L87.1946 13.4867L81.0028 37.2109C80.4101 39.4804 77.5493 40.2738 75.8116 38.6223L75.808 38.6189L70.784 33.9384L70.7825 33.937C69.7025 32.9114 69.2411 31.3318 69.6138 29.9137L74.2176 12.345Z" stroke="black" strokeWidth="1" />
            </svg>
         </div>
         <Routes location={displayLocation}>{children}</Routes>
      </>
   );
};

export default AnimatedRoutes;
