import React from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CaseTitle from './CasesComponent/CaseTitle';
import CaseText from './CasesComponent/CaseText';
import CaseImage from './CasesComponent/CaseImage';
import CaseBtn from './CasesComponent/CaseBtn';
import CaseTags from './CasesComponent/CaseTags';
import { useTranslation } from 'react-i18next';



function CaseItemSmall({ props }) {

   const { i18n } = useTranslation();

   return (
      <div className="col-lg-2 col-md-4">
         <article className={`case small ${props.light ? 'light' : ''}`}>
            <CaseImage
               linkImage={props.image}
               linkImageMobile={props.imageMobile}
               alt={i18n.language === 'ru' ? props.title : props.titleEn}
               linkSite={props.linkSite}
            >
               <CaseTags
                  tags={props.tags}
                  light={props.light}
               />
            </CaseImage>
            <CaseTitle title={i18n.language === 'ru' ? props.title : props.titleEn} />
            <CaseText>
               <p>
                  {i18n.language === 'ru' ? props.text : props.textEn}
               </p>
            </CaseText>
            <CaseBtn link={props.linkCase} />
         </article>
      </div>
   );
}

export default CaseItemSmall;