import React, { useEffect, useRef, useState } from 'react';
import './AnimateText.scss';
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

function AnimateTextEn() {
   const textRef = useRef(null);
   const cursorRef = useRef(null);
   const hiddenTextRef = useRef(null);
   const [textHeight, setTextHeight] = useState(0);

   const updateTextHeight = () => {
      if (hiddenTextRef.current) {
         const computedHeight = hiddenTextRef.current.getBoundingClientRect().height;
         setTextHeight(computedHeight);
         console.log(textHeight);
      }
   };

   useEffect(() => {
      // 1. Первый расчет высоты
      updateTextHeight();

      // 2. Анимация текста
      const textElement = textRef.current;
      const cursorElement = cursorRef.current;

      const text = `
         We develop designs and digital products <br />
         that help, not confuse
       `;

      gsap.to(textElement, {
         delay: 1.3,
         text: {
            value: text,
            speed: 0.7,
         },
         ease: "none",
         onStart: () => {
            cursorElement.classList.remove('blink');
         },
         onComplete: () => {
            cursorElement.classList.add('blink');
         },
      });

      // 3. Добавление обработчика события resize
      window.addEventListener('resize', updateTextHeight);

      // 4. Удаление обработчика при размонтировании компонента
      return () => {
         window.removeEventListener('resize', updateTextHeight);
      };
   }, []);

   return (
      <div className='animate_title'
         style={{ minHeight: `${textHeight}px` }}
      >
         <div className="static_text">
            Kata is a team of analysts, designers <br />
            and developers with a focus on product approach. <br />

         </div>
         <div
            className='tapping_text'
            ref={textRef}
         ></div>
         <span className='cursor blink' ref={cursorRef}>|</span>

         <div className="hidden_text" ref={hiddenTextRef}>
            Ката — это команда аналитиков, дизайнеров <br />
            и разработчиков с фокусом на продуктовый подход. <br />
            Разрабатываем дизайн и цифровые продукты, <br />
            которые помогают, а не сбивают с толку.

         </div>
      </div>
   );
}

export default AnimateTextEn;
