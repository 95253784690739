import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Preloader from './components/Preloader';
import FrontPage from './FrontPage';
import CaseCoconessa from './pages/Cases/CaseCoconessa/CaseCoconessa';
import AnimatedRoutes from './utilities/AnimatedRoutes';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './i18n';

function App() {
  return (


    <Router>
      <Header lang='RU' />
      <main>
        <AnimatedRoutes>
          <Route path="/" element={<FrontPage />} />
          {/* <Route path="/coconessa" element={<CaseCoconessa />} /> */}
        </AnimatedRoutes>
      </main >
      <Footer lang='RU' />
    </Router>


  );
}

export default App;
