import AnimateText from "../AnimateText/AnimateText"
import AnimateTextEn from "../AnimateText/AnimateTextEn";
import './FrontPageFirst.scss'
import { useTranslation } from 'react-i18next';

function FrontPageFirst() {
   const { i18n } = useTranslation();
   return (
      <div className="front_first">
         <div className="container">
            {i18n.language === 'ru' ? (
               <AnimateText />
            ) : (
               <AnimateTextEn />
            )}

         </div>
      </div>
   );
}

export default FrontPageFirst;