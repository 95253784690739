import { useMediaQuery } from 'react-responsive'
import CaseAuthorName from './CaseAuthorName';
import { useTranslation } from 'react-i18next';

function CaseAuthorAvatar({ link, alt, linkVideo, name }) {

   const { t } = useTranslation();
   const isMobile = useMediaQuery({
      query: '(max-width: 599px)'
   })

   return (
      <div className="case_avatar">
         <div className="case_avatar-avatar">
            <img src={link} alt={alt} />
         </div>
         {isMobile ? (
            <div className='case_avatar_info'>
               <CaseAuthorName>
                  <p>{name}</p>
               </CaseAuthorName>
               {
                  linkVideo &&
                  <a href={linkVideo} className="case_avatar-video_link">
                     {!isMobile &&
                        <div className="icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                              <g filter="url(#filter0_b_1361_1300)">
                                 <circle cx="15" cy="15" r="15" fill="#D4EAFF" />
                              </g>
                              <path d="M12.2864 9.82597C11.6199 9.45567 10.8008 9.93764 10.8008 10.7001V19.3011C10.8008 20.0636 11.6199 20.5455 12.2864 20.1753L20.0273 15.8748C20.7131 15.4938 20.7131 14.5075 20.0273 14.1265L12.2864 9.82597Z" fill="#2898FF" />
                              <defs>
                                 <filter id="filter0_b_1361_1300" x="-50" y="-50" width="130" height="130" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_1300" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1361_1300" result="shape" />
                                 </filter>
                              </defs>
                           </svg>
                        </div>
                     }
                     <span>{t("cases.Смотреть видеоотзыв")}</span>
                  </a>
               }
            </div>
         ) : (
            linkVideo &&
            <a href={linkVideo} className="case_avatar-video_link">
               {!isMobile &&
                  <div className="icon">
                     <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <g filter="url(#filter0_b_1361_1300)">
                           <circle cx="15" cy="15" r="15" fill="#D4EAFF" />
                        </g>
                        <path d="M12.2864 9.82597C11.6199 9.45567 10.8008 9.93764 10.8008 10.7001V19.3011C10.8008 20.0636 11.6199 20.5455 12.2864 20.1753L20.0273 15.8748C20.7131 15.4938 20.7131 14.5075 20.0273 14.1265L12.2864 9.82597Z" fill="#2898FF" />
                        <defs>
                           <filter id="filter0_b_1361_1300" x="-50" y="-50" width="130" height="130" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                              <feFlood floodOpacity="0" result="BackgroundImageFix" />
                              <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
                              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_1300" />
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1361_1300" result="shape" />
                           </filter>
                        </defs>
                     </svg>
                  </div>
               }
               <span>{t("cases.Смотреть видеоотзыв")}</span>
            </a>
         )}
      </div>
   );
}

export default CaseAuthorAvatar;